$('#frmLogin').submit(function(event) {
    event.preventDefault();
    $.ajax({
        url: '/',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        type: 'POST',
        data: $(this).serialize(),
        statusCode: {
            200: function() {
                Swal.fire({
                    type: 'success',
                    text: 'Login ok.'
                })
            },
        }
    });
});